import { ReactNode, useEffect } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import Preview from '../components/preview/Preview';

interface IsIndexLayout {
    children: ReactNode;
}

const IndexLayout = ({ children }: IsIndexLayout) => {
    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <div className="layout-container">
            <div className="layout-container__main">
                <Header />
                {children}
                <Footer />
            </div>
            <div className="layout-container__secondary">
                <Preview />
            </div>
        </div>
    );
};

export default IndexLayout;
