import { useEffect, useState } from 'react';

type IsImage = {
    src: string;
    alt: string;
};

const Preview = () => {
    const [images, setImages] = useState<IsImage[]>([
        {
            src: '/preview-1.webp',
            alt: 'The Mancunian Bar',
        },
        {
            src: '/preview-2.webp',
            alt: 'CC. Vincent Rivaud',
        },
        {
            src: '/preview-3.webp',
            alt: 'CC. Nathan J Hilton. Just Between Friends Coffee',
        },
        {
            src: '/preview-4.webp',
            alt: ' CC. Roman Odintsov',
        },
        {
            src: '/preview-5.webp',
            alt: 'CC. Florent B',
        },
    ]);

    useEffect(() => {
        let counter: number = 0;
        const intervalTime: number = 4000;

        function showHideImages() {
            const previewContainer = document.querySelectorAll('.preview .preview__item') as NodeListOf<Element>;

            // Hide all images
            previewContainer.forEach(image => image.classList.remove('preview__item--is-active'));

            // Show the image with the matching data-id
            const imageToShow = document.querySelector(`[data-id="${counter}"]`);
            if (imageToShow) imageToShow.classList.add('preview__item--is-active');

            counter++;

            if (counter === images.length) counter = 0;
        }

        // Call the function initially
        showHideImages();

        // Set interval to call the function
        setInterval(showHideImages, intervalTime);
    }, [images]);

    return (
        <div className="preview">
            {images.map((img, idx) => (
                <div
                    key={idx}
                    data-id={idx}
                    className="preview__item"
                >
                    <div className="preview__item-credit">{img.alt}</div>
                    <img
                        src={img.src}
                        alt={img.alt}
                    />
                </div>
            ))}
        </div>
    );
};

export default Preview;
