// import { Link } from 'react-router-dom';
import { setCookie } from '../../scripts/helpers/cookies';

const CookiesPrompt = () => {
    const gaOptout = () => {
        setCookie('ga-disabled', true, 30); // TODO: Realistically this is doing nothing. Ideally true/alse value needs setting dynamicaly for the user ...
        setCookie('ga-hide-prompt', true, 30);
        const prompt = document.querySelector('.cookies-prompt');
        prompt?.classList.add('hide-prompt');
        // https://stackoverflow.com/questions/40358434/typescript-ts7015-element-implicitly-has-an-any-type-because-index-expression
    };

    const gaAccept = () => {
        setCookie('ga-hide-prompt', true, 30);
        const prompt = document.querySelector('.cookies-prompt');
        prompt?.classList.add('hide-prompt');
        console.log('clicked?');
    };

    return (
        <div className="cookies-prompt">
            <div className="cookies-prompt__wrap">
                <div className="cookies-prompt__title">This website uses cookies</div>
                <p className="cookies-prompt__text">
                    To improve site navigation, analyse site usage, and support our marketing efforts, please click
                    "Accept" on this page. By doing so, you consent to cookies being stored on your device.
                </p>
                {/* <Link
                    to="/privacy-policy/"
                    className="cookies-prompt__link"
                >
                    View our Privacy Policy
                </Link> */}
            </div>
            <button
                onClick={() => gaAccept()}
                className="cookies-prompt__button button button--alt"
            >
                Accept
            </button>
        </div>
    );
};

export default CookiesPrompt;
