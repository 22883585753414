import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="site-footer__col">
                <h3 className="site-footer__heading">Follow us</h3>
                <div className="site-footer__social">
                    <a
                        href="https://www.instagram.com/quaintconnections/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ReactSVG src="icon-instagram.svg" />
                    </a>
                    <a
                        href="https://www.facebook.com/profile.php?id=100094771723603"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ReactSVG src="icon-facebook.svg" />
                    </a>
                </div>
            </div>
            <div className="site-footer__col">
                <h3 className="site-footer__heading">Register</h3>
                <Link
                    to={'/register'}
                    className="site-footer__link"
                >
                    Add your business to our pre-launch register
                </Link>
            </div>
            <div className="site-footer__socket">
                © 2023 Quaint Connections.{' '}
                <a
                    className="site-footer__socket-link"
                    href="https://www.thirty3digital.co.uk"
                    target="_blank"
                    rel="noreferrer"
                >
                    Thirty3 Digital
                </a>{' '}
                Ltd is the copyright holder and owner of Quaint Connections. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
