import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const Header = () => {
    return (
        <header className="site-header">
            <a
                href={'/'}
                className="site-header__logo"
            >
                <ReactSVG src="logo.svg" />
            </a>
        </header>
    );
};

export default Header;
