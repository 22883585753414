const SignupForm = () => {
    return (
        <div className="form-container">
            <p>
                Get a taste of Manchester's exciting food and drink scene, be in the know about the latest platform
                build updates! Subscribe now to our newsletter for exclusive access - you wouldn't want to miss out.
            </p>
            <div>
                <div
                    className="ml-embedded"
                    data-form="aufHWx"
                ></div>
            </div>
        </div>
    );
};

export default SignupForm;
