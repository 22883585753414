import { useState, useEffect, useCallback } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexLayout from './layouts/IndexLayout';
import TextBlock from './components/content/TextBlock';
import SignupForm from './components/forms/SignupForm';
import RegisterForm from './components/forms/RegisterForm';
import CookiesPrompt from './components/global/CookiePrompt';

function App() {
    const [cookie, setCookie] = useState(true);
    const [meta, setMeta] = useState({
        title: 'Quaint Connections | Manchester food and drink directory',
        description:
            'Helping people discover the vibrant heart of Manchester through its local independent food and drink scene.',
        creator: 'quaintconnections',
        type: 'article',
    });
    const [page, setPage] = useState({
        landing: {
            content:
                '<div className="prefix">Coming soon</div><h1>Get notified when we launch</h1><p className="p-xl">Helping people discover the vibrant heart of Manchester through its local independent food and drink scene.</p>',
        },
        register: {
            content:
                "<h1>Join our pre-launch register</h1><p>Ready to embark on an exciting journey? Include a business today to help shine a spotlight on the dynamic and lively flavors of Manchester's thriving independent food and drink scene.</p>",
        },
        success: {
            content:
                "<h1>Your all signed up</h1><p className='p-xl'>Thank you for signing up for our newsletter! We're excited to have you on board.</p><p>Keep an eye on your inbox as you'll receive a confirmation email shortly. Please click the confirmation link to verify your email address and complete the signup process.</p><p>Once your email address is confirmed, you'll be all set to stay updated on the progress of launching Quaint Connections. We'll keep you informed about the latest and greatest food and drinks venues in Manchester. We're thrilled to have you as part of Quaint Connections, and we can't wait to share all the exciting updates with you.</p>",
        },
        error: {
            content: '<h1>404</h1><p>The page you are looking for cannot be found.</p>',
        },
        privacy: {
            content: '<h1>Privacy policy</h1>',
        },
    });

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        document.cookie.match(/^(.*;)?\s*ga-hide-prompt\s*=\s*[^;]+(.*)?$/) ? setCookie(false) : '';
    }, []);

    const renderCookiePromt = useCallback(() => <>{cookie && <CookiesPrompt />}</>, [cookie]);

    return (
        <BrowserRouter>
            <HelmetProvider>
                <Helmet>
                    <title>{meta.title}</title>
                    <meta
                        name="description"
                        content={meta.description}
                    />
                    <meta
                        property="og:type"
                        content={meta.type}
                    />
                    <meta
                        property="og:title"
                        content={meta.title}
                    />
                    <meta
                        property="og:description"
                        content={meta.description}
                    />
                    <meta
                        name="twitter:creator"
                        content={meta.creator}
                    />
                    <meta
                        name="twitter:card"
                        content={meta.type}
                    />
                    <meta
                        name="twitter:title"
                        content={meta.title}
                    />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <script
                        async
                        src="/ml.js"
                    ></script>
                </Helmet>
                {renderCookiePromt()}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <IndexLayout>
                                <TextBlock content={page.landing.content} />
                                <SignupForm />
                            </IndexLayout>
                        }
                    />
                    <Route
                        path="/register"
                        element={
                            <IndexLayout>
                                <TextBlock content={page.register.content} />
                                <RegisterForm />
                            </IndexLayout>
                        }
                    />
                    <Route
                        path="/success"
                        element={
                            <IndexLayout>
                                <TextBlock content={page.success.content} />
                            </IndexLayout>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <IndexLayout>
                                <TextBlock content={page.error.content} />
                            </IndexLayout>
                        }
                    />
                </Routes>
            </HelmetProvider>
        </BrowserRouter>
    );
}

export default App;
