const RegisterForm = () => {
    return (
        <div>
            <div className="form-container form-spacing">
                <form
                    action="https://getform.io/f/48ecc680-435d-4752-b4cf-380924724996"
                    method="POST"
                    encType="multipart/form-data"
                >
                    <input
                        type="text"
                        name="name"
                        className="form-input"
                        placeholder="Businesses name*"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        className="form-input"
                        placeholder="Email address*"
                        required
                    />
                    <input
                        type="url"
                        className="form-input"
                        name="website"
                        placeholder="Website URL"
                    />
                    <input
                        type="text"
                        className="form-input"
                        name="instagram"
                        placeholder="@ Instagram handle"
                    />
                    <input
                        type="hidden"
                        name="_gotcha"
                    />
                    <button
                        type="submit"
                        className="button button--submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;
