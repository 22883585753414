import ReactGA from 'react-ga4';

// https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455
// https://www.npmjs.com/package/react-ga4

/**
 * Track campaign source for users landing on the site
 */
export const trackFromCampaign = () => {
    let params = new URLSearchParams(document.location.search);
    let source = params.get('utm_source');

    if (source) {
        ReactGA.event({
            category: 'Campaign Tracking',
            action: 'Campaign Source',
            label: source,
        });
    }
};
