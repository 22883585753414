import parse from 'html-react-parser';
interface IsContent {
    content: string;
}

const TextBlock = ({ content }: IsContent) => {
    return <section>{parse(content)}</section>;
};

export default TextBlock;
